<template>
    <div class="tree-content">
        <slot name="filter-area">
            <div class="search-area">
                <el-input
                        size="small"
                        :placeholder="$t('liveTracking.vehicleNameOrPlateNumber')"
                        v-model="filterNameKey"
                        @input="nameFilter"
                        clearable
                        suffix-icon="">
                </el-input>
            </div>
        </slot>
        <el-tree
                v-if="isShowTree()"
                class="filter-tree scroll-popover"
                :props="defaultProps"
                :data="treeData"
                node-key="Id"
                accordion
                :default-checked-keys="defaultVehicles"
                :default-expanded-keys="[treeParentId]"
                :show-checkbox="true"
                :check-on-click-node="true"
                :empty-text='$t("globalText.noData")'
                highlight-current
                :check-strictly='checkStrictly'
                @node-click="handleNodeClick"
                @check-change="handleCheckChange"
                :filter-node-method="filterNode"
                @node-expand="handleNodeExpand"
                ref="tree"
        >
            <div class="custom-tree-node" slot-scope="{ node, data }" onselectstart="return false;">
                <div v-if="!data.parentId">
                    <span :title="data.Name">{{data.Name}}</span>
                </div>
                <div v-else-if="data.type=='fleet'" class="fleet-area">
                    <span v-if="data.Id != 'unassignedFleet'" class="via-vehicles icon-big"
                          :style="{color:data.Color}"></span>
                    <span class="select-hover-area"
                          :class="{noFleetIcon:data.Id == 'unassignedFleet'}"
                          :ref="'select-hover-area-' + data.Id">
                         <span :style="{fontWeight: data.Id == disabledFleetId ? 'bolder':'normal',maxWidth:fleetNameWidth(node,data)}"
                               class="text-overflow-ellipsis" :title="data.Name"
                               :class="{'current-node': node.isCurrent}">{{ data.Name }}</span>
                         <span v-if="hasCollisionVel(data)" class="via-warning collision-icon"></span>
                         <span v-if="node.childNodes && showNumber" class="number-area"
                               :ref="'number-area-' + data.Id">
                        <span class="via-car icon-small"></span>
                        <span>{{getCurrentVisibleVehicleNumbers(node)}}/{{getRegisteredVehicleNumbers(node)}}</span>
                    </span>
                    </span>

                </div>
                <div v-else-if="data.type=='vehicle'" class="vehicle-area" :id="'vehicle-area-'+ data.Id"
                     :class="{'offroad-node': !data.isOnRoad,'current-node': node.isCurrent,'no-checkbox':!vehicleCheckbox}">
                    <div v-if="!isShowState"
                         class="text-overflow-ellipsis"
                         :style="{maxWidth:vehicleNameWidth(node,data)}"
                         :title="vehicleNameInTree(data)">
                        {{ data.Name }} ({{data.Id}})
                    </div>
                    <template v-else>
                        <div class="text-overflow-ellipsis"
                             :style="{maxWidth:vehicleNameWidth(node,data)}"
                             :class="{'no-driver-vehicle' : data.noDriver || data.isUnPermittedDriver}"
                             :title="vehicleNameInTree(data)">
                            {{ data.Name }} ({{data.Id}})
                        </div>
                        <!--<div v-show='node.isCurrent'><span class="via-map-marker current-node-icon"></span></div>-->
                        <div v-show="data.isCallRequest" class="tc-icon via-microphone microphone-icon "></div>
                        <div v-show="data.isNoSignal" class="tc-icon via-wireless no-signal-icon"></div>
                        <div v-show="data.isParkingCollision" class="tc-icon via-Parking-alert collision-icon"
                             :title="getAlertsInfo(data)"></div>
                        <div v-if="data.isMoreALerts" class="tc-icon via-warning collision-icon"
                             :title="getAlertsInfo(data)"></div>
                        <template v-else>
                            <div v-show="data.isCollision" class="tc-icon via-car-crash collision-icon"
                                 :title="getAlertsInfo(data)"></div>
                            <div v-show=" data.isDriverAlert" class="tc-icon via-Driver-alert collision-icon"
                                 :title="getAlertsInfo(data)"></div>
                            <div v-show="data.isUnPermittedDriver" class="tc-icon via-Unpermitted collision-icon"
                                 :title="getAlertsInfo(data)"></div>

                        </template>
                    </template>

                </div>
            </div>
        </el-tree>
        <div v-else class="data-loading">
            {{$t('globalText.loading')}}
        </div>
    </div>


</template>

<script>
    import elTree from "./elementUI/el-tree/src/tree"
    import $ from 'jquery';
    import Sort from "@/assets/js/Sort";

    export default {
        name: "Tree",
        components: {
            elTree
        },
        props: {
            dataObj: {type: Object, default: null},
            isOnRodeFilter: {type: Object},
            isShowState: {type: Boolean, default: false},
            checkStrictly: {type: Boolean, default: true},
            fleetCheckbox: {type: Boolean, default: false},
            vehicleCheckbox: {type: Boolean, default: false},
            disabledFleetId: {type: String, default: ""},
            showNumber: {type: Boolean, default: false},
            defaultVehicles: {type: Array},
            defaultNodeKey: {type: [String, Number], default: null},
            allStateInfo: {type: Array, default: null},
            updateStateInfo: {type: Object, default: null},
            callRequestStateInfo: {type: Object, default: null},
            isShowUnAssignedVehicle: {
                type: Boolean, default: false
            },
            isSetDefaultClickNode: {
                type: Boolean, default: false
            }
        },
        data() {
            return {
                defaultProps: {
                    label: 'Name'
                },
                treeData: null,
                filterNameKey: '',
                filterArr: [{
                    prop: ['Name', 'PlateNumber'],
                    key: '',
                    type: 1
                },
                    {
                        prop: 'isRegistered',
                        key: true,
                        type: 0
                    }
                ],
                isCurrentOnRoadTree: true,
                treeParentId: "",
                onRoadTreeCurrentKey: null,
                offRoadTreeCurrentKey: null,
                callRequestStates: null,
                isFirstAllStateInfo: true,
            };
        },
        watch: {
            defaultVehicles: function (val) {
                if (this.$refs.tree)
                    this.$refs.tree.setCheckedKeys(val);
            },
            isOnRodeFilter: function (val) {//val为一个数组，可能保护多个过滤关键字比如[{prop:'isOnRoad',key:true,type:'0'}]  ,其中type=0 代表精确查找，1代表模糊查找
                this.resetFilterArr(val);
                this.updateTree();
                if (val) {
                    this.isCurrentOnRoadTree = val.key;
                    this.$nextTick(() => {
                        this.resetStateWhileSwitchTree(this.isCurrentOnRoadTree);
                    })
                }

            },
            allStateInfo: function (val) {
                // if (val && val.forEach) {
                //     val.forEach((item, index) => console.log('allStateInfo: ', index, JSON.stringify(item)));
                // }
                this.updateAllStateInfo(val);
            },
            updateStateInfo: function (val) {
                let tree = this.$refs.tree;
                if (tree && tree.getCurrentNode() && val.Id === tree.getCurrentNode().Id) {
                    //console.log('updateStateInfo', tree.getCurrentNode().Id, tree.getCurrentNode().isOnRoad, JSON.stringify(val));
                }
                this.updateTreeState(val);
                this.updateTree(); //根据state filter tree
            },
            callRequestStateInfo: function (val) {
                this.updateCallStateInfo(val);
                console.log("callRequestStateInfo");
            },
            fleetData() {
                if (!this.isShowUnAssignedVehicle)
                    this.treeData = this.setTreeData();
            },
            unAssignedVelData: function () {
                this.treeData = this.setTreeData();
            },
            treeData(val) {
                if (val && val.length != 0) {
                    if (this.isShowState) {
                        this.updateAllStateInfo(this.allStateInfo);
                    }
                }
            },
            treeShow(val) {
                if (val) {
                    console.log("tree is show,callRequestStates = " + this.callRequestStateInfo);
                    this.updateCallRequestStates(this.callRequestStates);
                }
            },
            callRequestStates(val) {
                console.log("callRequestStates coming,tree show is " + this.isShowTree());
                this.updateCallRequestStates(val);
            },
            defaultNodeKey(val) {
                console.log("defaultNodeKey" + val);
                if (val) {
                    this.selectNodeById(val);
                }

            }
        },
        computed: {
            // defaultNodeKey: function () {
            //      return this.$store.getters.defaultTreeNodeKey;
            // },
            fleetData: function () {
                return this.$store.state.fleetData.fleetList;
            },
            unAssignedVelData: function () {
                return this.$store.getters.unassignedVehicleList(true);
            },
            fleetNameWidth: function () {
                return function (node, data) {
                    if (node.visible) {
                        if (this.$refs['select-hover-area-' + data.Id] && this.$refs['number-area-' + data.Id]) {
                            let width = this.$refs['select-hover-area-' + data.Id].scrollWidth - this.$refs['number-area-' + data.Id].scrollWidth - 12;
                            if (this.hasCollisionVel(data))
                                width -= 28;
                            return width + 'px'
                        }
                    }
                    return "120px"
                }
            },
            vehicleNameWidth: function () {
                return function (node, data) {
                    return this.getVehicleNameMaxWidth(node, data);
                }

            },
            vehicleNameInTree() {
                return function (data) {
                    return data.Name + "(" + data.Id + ")"
                }
            },
            treeShow() {
                return this.isShowTree();
            }
        },
        methods: {
            updateAllStateInfo(allStateInfo) {
                this.$nextTick(() => {
                    var tree = this.$refs.tree;
                    if (allStateInfo && tree) {
                        allStateInfo.forEach((item) => {
                            this.updateTreeState(item);
                        })
                        this.updateTree();
                        if (this.isFirstAllStateInfo && this.isSetDefaultClickNode) {
                            this.selectDefaultNode();
                            this.isFirstAllStateInfo = false;
                        }
                    }

                })
            },
            updateCallRequestStates(callRequestStates) {
                this.$nextTick(() => {
                    var tree = this.$refs.tree;
                    if (callRequestStates && tree) {

                        callRequestStates.forEach((item) => {
                            this.updateCallStateInfo(item);
                        })
                    }
                })

            },
            updateCallStateInfo(callStateInfo) {
                var tree = this.$refs.tree;
                if (tree) {
                    console.log("update node:" + callStateInfo.Id);
                    var node = tree.getNode(callStateInfo.Id);
                    if (node) {
                        var nodeData = node.data;
                        nodeData.isCallRequest = !callStateInfo.isCallRequestAnswered;
                        if (!callStateInfo.isCallRequestAnswered) {
                            var dealWithCallTimeOut = (data) => {
                                console.log("call request timeout");
                                data.isCallRequest = false;
                                this.emitCallRequestState(node, false);
                                if (nodeData.CallTimeOutHander)
                                    clearTimeout(nodeData.CallTimeOutHander);
                            }
                            if (nodeData.CallTimeOutHander)
                                clearTimeout(nodeData.CallTimeOutHander);
                            nodeData.CallTimeOutHander = setTimeout(() => {
                                dealWithCallTimeOut(nodeData);
                            }, callStateInfo.TTL * 1000);
                            this.emitCallRequestState(node, true)
                        } else {
                            this.emitCallRequestState(node, false)
                        }

                    }
                }
            },
            emitCallRequestState(node, isBlink) {
                if (node.isCurrent) {
                    this.$emit("callRequestStateChange", {Id: node.data.Id, isBlink: isBlink});
                }
            },
            hasCollisionVel(data) {
                let hasCollision = false;
                if (data.children) {
                    data.children.forEach((item) => {
                        if (this.isCurrentOnRoadTree) {
                            if (item.isCollision || item.isUnPermittedDriver || item.isDriverAlert)
                                hasCollision = true;
                        }
                        else {
                            if (item.isParkingCollision)
                                hasCollision = true;
                        }

                    })
                }
                return hasCollision;
            },
            isShowTree() {
                if (this.treeData) {
                    if (this.isShowState && !this.allStateInfo) {
                        return false;
                    }
                    return true
                }
                return false;
            },
            composeUnAssignedFleet() {
                var unAssignedFleet = {
                    Id: "unassignedFleet",
                    Name: this.$t('liveTracking.unassignedVehicles'), // "UnAssigned Vehicles",
                    Vehicles: [...this.unAssignedVelData]
                }
                return unAssignedFleet;
            },
            setLastVisibleFleet() {
                setTimeout(() => {
                    $('.el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node').removeClass("last-visible-fleet");
                    $('.el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node').not(':hidden').last().addClass("last-visible-fleet");

                }, 50)
            },
            transferToTreeData(parentId, data) {
                var fleets = {
                    Id: data.Id,
                    Name: data.Name,
                    type: "fleet",
                    fleetData: data,
                    Color: data.Color,
                    showCheckbox: this.fleetCheckbox,
                    parentId: parentId,
                    hasCollisionVel: false,
                };
                if (!parentId) {
                    fleets.Name = "All";
                    this.treeParentId = data.Id;
                }
                var subFleets = [];
                if (data.Sub) {
                    subFleets = data.Sub.map((item) => {
                        return this.transferToTreeData(item.Id, item);
                    });
                }
                var subVehicles = [];
                if (data.Vehicles) {
                    subVehicles = this.nameSort(data.Vehicles).map((item) => {
                        var vehicles = {
                            Id: item.Id,
                            PlateNumber: item.Id,
                            Name: item.Name,
                            FleetId: item.FleetId,
                            parentId: parentId,
                            isRegistered: item.DeviceId ? true : false,
                            type: "vehicle",
                            vehicleData: item,
                            Color: data.Color,
                            showCheckbox: this.vehicleCheckbox,
                            isNoSignal: false,
                            isCollision: false,
                            isParkingCollision: false,
                            isDriverAlert: false,
                            isUnPermittedDriver: false,
                            isMoreALerts: false,
                            isCallRequest: false,
                            isOnRoad: false,
                            noDriver:false
                        }
                        vehicles.vehicleData.Color = data.Color;

                        return vehicles;
                    })
                }
                fleets.children = subFleets.filter((item) => {
                    return item.children.length > 0;
                }).concat(subVehicles);

                return fleets;
            },
            getTreeDataFromList(fleetList) {
                if (fleetList) {
                    if (this.isShowUnAssignedVehicle) {
                        if (!this.unAssignedVelData) {
                            this.$store.dispatch("getUnassignedVehicleList");
                            return null;
                        }

                    }
                    var newFleetList = JSON.parse(JSON.stringify(fleetList));
                    newFleetList.Sub = newFleetList.Sub ? this.nameSort(newFleetList.Sub) : [];
                    if (this.isShowUnAssignedVehicle)
                        newFleetList.Sub.push(this.composeUnAssignedFleet());
                    if (newFleetList.Sub.length === 0)
                        return [];
                    var data = [].concat(this.transferToTreeData(null, newFleetList));
                    return data;
                }
                else {
                    this.getFleetList();
                    return null;
                }

            },
            nameSort(data) {
                if (!data) return data;
                return data.sort((itemA, itemB) => {
                    return Sort.NameSort(itemA.Name, itemB.Name);
                })
            },
            setTreeData() {
                if (this.dataObj) {
                    return this.getTreeDataFromList(this.dataObj);
                } else {
                    return this.getTreeDataFromList(this.fleetData);
                }

            },
            updateTree() {
                var tree = this.$refs.tree;
                if (tree)
                    tree.filter(this.filterArr);
                this.setLastVisibleFleet();
            },
            selectDefaultNode() {
                this.$nextTick(() => {
                    if (this.defaultNodeKey) {
                        this.onRoadTreeCurrentKey = this.defaultNodeKey
                    } else
                        this.onRoadTreeCurrentKey = this.getFirstVisibleVehicle();
                    this.selectNodeById(this.onRoadTreeCurrentKey);
                })
            },
            resetFilterArr(newFilter) {
                if (newFilter) {
                    var index = this.filterArr.findIndex((item) => item.prop == newFilter.prop);
                    if (index >= 0)
                        this.filterArr[index] = newFilter;
                    else
                        this.filterArr.push(newFilter);
                }

            },
            nameFilter() {
                this.filterArr[0].key = this.filterNameKey;
                this.$refs.tree.filter(this.filterArr);
            },
            getOnRoadFilter(filter) {
                if (filter && filter.prop == "isOnRoad")
                    return true;
                return false;
            },
            filterNode(value, data) {
                if (!value || value.length == 0) return true;
                var returnVal = false;
                for (var i = 0; i < value.length; i++) {
                    if (value[i]) {
                        if (value[i].type == '0') {
                            if (data[value[i].prop] != undefined && data[value[i].prop] === value[i].key)
                                returnVal = true;
                            else {
                                return false;
                            }
                        } else {
                            let search = (data, prop) => {
                                if (data[prop] && data[prop].toLowerCase().indexOf(value[i].key.toLowerCase()) >= 0) {
                                    return true;
                                }
                                return false;
                            }
                            let find = false;
                            if (value[i].prop instanceof Array) {
                                value[i].prop.forEach((item) => {
                                    find = search(data, item) || find;
                                })
                            } else {
                                find = search(data, value[i].prop);
                            }
                            if (find)
                                returnVal = find;
                            else
                                return false;
                        }
                    }
                }
                return returnVal;
            },
            isHaveTwoTrue(a, b, c) {
                return (a && b) || (b && c) || (a && c);
            },
            updateTreeState(stateInfo) {
                var tree = this.$refs.tree;
                if (tree) {
                    var node = tree.getNode(stateInfo.Id);
                    if (node) {
                        var nodeData = node.data;
                        if (node.isCurrent && (nodeData.isOnRoad && !stateInfo.isOnRoad)) {
                            console.log('****** vehicle off-road stateInfo ******', new Date().toString());
                            console.log(JSON.stringify(stateInfo, null, 4));
                            this.handleCurrentNodeOffRoad(nodeData);
                            this.onRoadTreeCurrentKey = null;
                            this.selectNodeById(null);
                        }
                        if (node.isCurrent && (!nodeData.isOnRoad && stateInfo.isOnRoad)) {
                            this.handleCurrentNodeOnRoad(nodeData);
                            this.offRoadTreeCurrentKey = null;
                            this.selectNodeById(null);
                        }


                        nodeData.isNoSignal = stateInfo.isNoSignal && stateInfo.isOnRoad;
                        nodeData.isCollision = stateInfo.isCollision && stateInfo.isOnRoad;
                        nodeData.isDriverAlert = stateInfo.isDriverAlert && stateInfo.isOnRoad;
                        nodeData.isUnPermittedDriver = stateInfo.isUnPermittedDriver && stateInfo.isOnRoad;
                        nodeData.isMoreALerts = this.isHaveTwoTrue(nodeData.isCollision, nodeData.isDriverAlert, nodeData.isUnPermittedDriver);
                        nodeData.isParkingCollision = stateInfo.isParkingCollision && !stateInfo.isOnRoad;
                        nodeData.isOnRoad = stateInfo.isOnRoad;
                        nodeData.isRegistered = true;

                        nodeData.noDriver = stateInfo.DriverId && stateInfo.DriverId !== '0' ? false : true;
                        nodeData.DeviceId = stateInfo.DeviceId;
                    }
                }
            },
            getAlertsInfo(data) {
                // var alertInfo = "";
                let alert_list = [];
                if (data.isCollision)
                    alert_list.push(this.$tc('liveTracking.collisionAlerts', 1));
                // alertInfo += "Collision Alert\n";
                if (data.isDriverAlert)
                    alert_list.push(this.$tc('liveTracking.driverAlerts', 1));
                // alertInfo += "Driver Alert\n";
                if (data.isParkingCollision)
                    alert_list.push(this.$tc('liveTracking.parkingAlerts', 1))
                // alertInfo += "Parking Alert\n";
                if (data.isUnPermittedDriver)
                    alert_list.push(this.$tc('liveTracking.unpermittedDrivers', 1))
                // alertInfo += "Unpermitted Driver\n";
                return alert_list.join('\n');

            },
            handleCurrentNodeOffRoad(nodeData) {
                this.$emit("currentVehicleChange", nodeData);
                setTimeout(() => {
                    let msg = this.$t('popUpMsg.liveTracking.vehicleName_PlateNumber_IsOffRoad', {
                        vehicleName: nodeData.Name,
                        plateNumber: nodeData.Id
                    }); // `${nodeData.Name} (${nodeData.Id})  is off-road.`
                    this.$customAlert(msg).then(() => {
                        // this.selectDefaultNode();
                    });
                }, 2000);

            },
            handleCurrentNodeOnRoad(nodeData) {
                this.$emit("currentVehicleChange", nodeData);
                let msg = this.$t('popUpMsg.liveTracking.vehicleName_PlateNumber_IsOnRoad', {
                    vehicleName: nodeData.Name,
                    plateNumber: nodeData.Id
                }); // `${nodeData.Name} (${nodeData.Id})  is on-road.`
                this.$customAlert(msg).then(() => {
                    // this.selectDefaultNode();
                });
            },
            getCheckedNodes() {
                if (this.$refs.tree)
                    return this.$refs.tree.getCheckedNodes();
            },
            expandParents(node) {
                node.expand();
                this.updateSubVehicleNameMaxWidth(node);
                if (node.parent) {
                    node = node.parent;
                    this.expandParents(node);
                }
            },
            collapseAllNode(parentID) {
                var tree = this.$refs.tree;
                if (tree)
                    tree.getNode(parentID).childNodes.forEach((node) => {
                        node.collapse();
                    })
            },
            selectNodeById(id) {
                var tree = this.$refs.tree;
                if (tree) {
                    if (!id) {
                        this.$refs.tree.setCurrentKey(null);
                        return;
                    }
                    var node = this.$refs.tree.getNode(id);
                    if (node) {
                        this.collapseAllNode(this.treeParentId);//先收起所有节点
                        this.expandParents(node);//再展开选择的节点
                        this.$refs.tree.setCurrentKey(id);
                        setTimeout(() => {
                            this.updateVehicleNameMaxWidth(node);
                        }, 50);
                        this.$emit("node-click", node.data);
                    } else {
                        this.$refs.tree.setCurrentKey(null);
                    }
                    this.updateCurrentNodeKey(id);
                }
            },
            getCurrentKey(isOnRoadTree) {
                if (this.$refs.tree) {
                    var currentNode = this.$refs.tree.getCurrentNode();
                    if (currentNode) {
                        return currentNode.Id
                    }
                }
                return "";
            },
            getFirstVisibleVehicle() {
                if (this.$refs.tree) {
                    var parentNode = this.$refs.tree.getNode(this.treeParentId);
                    if (parentNode) {
                        var fleetNodes = parentNode.childNodes;
                        for (var i = 0; i < fleetNodes.length; i++) {
                            if (fleetNodes[i].visible) {
                                var vehiclesNode = fleetNodes[i].childNodes;
                                if (vehiclesNode.length > 0)
                                    for (var j = 0; j < vehiclesNode.length; j++) {
                                        if (vehiclesNode[j].visible)
                                            return vehiclesNode[j].data.Id;
                                    }
                            }
                        }
                        return "";
                    }
                }
                return "";
            },
            resetStateWhileSwitchTree(isOnRoadTree) {
                this.collapseAllNode(this.treeParentId);
                if (isOnRoadTree) {
                    this.offRoadTreeCurrentKey = this.getCurrentKey(false);
                    this.selectNodeById(this.onRoadTreeCurrentKey);
                } else {
                    this.onRoadTreeCurrentKey = this.getCurrentKey(true);
                    this.selectNodeById(this.offRoadTreeCurrentKey);
                    console.log("resetStateWhileSwitchTree : this.onRoadTreeCurrentKey" + this.onRoadTreeCurrentKey);
                }
            },
            getCheckedKeys(id) {
                return this.$refs.tree.getCheckedKeys(id);
            },
            getCurrentVisibleVehicleNumbers(node) {
                return node.childNodes ? node.childNodes.filter((item) => {
                    return item.visible;
                }).length : 0;
            },
            getRegisteredVehicleNumbers(node) {
                return node.childNodes ? node.childNodes.filter((item) => {
                    return item.data.isRegistered;
                }).length : 0;
            },
            getVehicleNameMaxWidth: function (node, data) {
                //console.log(data.Id+":" + node.visible);
                if (node.visible) {
                    if ($('#vehicle-area-' + data.Id).length > 0) {
                        let width = $('#vehicle-area-' + data.Id).width();
                        if(data.Id=="SEWS372")
                            //console.log(data.Id+":width" + width);
                        if (width) {
                            width = width - 12;
                            // if (node.isCurrent) {
                            //     width -= 24;
                            // }
                            if (data.isMoreALerts) {
                                width -= 31;
                            } else {
                                if (data.isCollision) {
                                    width -= 31;
                                }
                                if (data.isDriverAlert) {
                                    width -= 31;
                                }
                                if (data.isUnPermittedDriver) {
                                    width -= 31;
                                }
                            }
                            if (data.isNoSignal) {
                                width -= 31;
                            }
                            if (data.isParkingCollision) {
                                width -= 31;
                            }
                            if (data.isCallRequest) {
                                width -= 31;
                            }
                            return parseInt(width) + 'px';
                        }
                    }
                }

                return '127px';
            },
            updateVehicleNameMaxWidth(node) {
                let width = this.getVehicleNameMaxWidth(node, node.data);
                $('#vehicle-area-' + node.data.Id + " .text-overflow-ellipsis").css("max-width", width);
            },
            updateSubVehicleNameMaxWidth(node) {
                setTimeout(() => {
                    node.childNodes.forEach((item) => {
                        this.updateVehicleNameMaxWidth(item);
                    });
                }, 50)
            },
            updateCurrentNodeKey(id) {
                if (this.isCurrentOnRoadTree) {
                    this.onRoadTreeCurrentKey = id;
                    console.log("node click: this.onRoadTreeCurrentKey =" + this.onRoadTreeCurrentKey);
                    this.$store.commit("setCurrentOnRoadTreeNodeId", id);
                } else {
                    this.offRoadTreeCurrentKey = id;
                    this.$store.commit("setCurrentOnRoadTreeNodeId", null);
                }


            },
            handleNodeExpand(data, node) {
                this.setLastVisibleFleet();
                this.updateSubVehicleNameMaxWidth(node);
                console.log("handleNodeExpand");
            },
            handleNodeClick(data, node) {
                this.setLastVisibleFleet();
                this.updateCurrentNodeKey(data.Id);
                this.$emit("node-click", data);
                // console.log("node=" + JSON.stringify(node));
            },
            handleCheckChange(data, checked) {
                this.$emit("check-change", data, checked);
            },
            getFleetList() {
                this.$store.dispatch("getFleetList").then(() => {
                    if (this.isShowUnAssignedVehicle)
                        this.$store.dispatch("getUnassignedVehicleList");
                    if (this.isShowState)
                        this.getWebRtcCallList();
                })
            },
            getWebRtcCallList() {
                this.$serverApi.webRtcApi.getRtcCalledList({Type: 0}, (data) => {
                    console.log("call Request data ==>")
                    this.callRequestStates = data.map((item) => {
                        var state = {
                            isCallRequestAnswered: false,
                            TTL: item.TTL,
                            Id: item.VehicleId
                        }
                        console.log(state);
                        return state;
                    })
                    console.log("call Request data end ==>")
                });
            }
        },
        created() {
            this.getFleetList();
            this.resetFilterArr(this.isOnRodeFilter);
        },
        beforeDestroy() {
            this.$store.commit("setCurrentOnRoadTreeNodeId", null);
        }

    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .tree-content {
        background-color: $color_white;
        height: calc(100% - 52px);
    }

    .search-area {
        background-color: $color_gray_first
        padding: 10px 10px;
    }

    .custom-tree-node {
        color: $color_black_first;
        width: calc(100% - 10px);
        text-align: left;
    }

    .custom-tree-node .fleet-area {
        font-size: 15px;
    }

    .custom-tree-node .fleet-name {
        width: 100px;
    }

    .text-overflow-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .custom-tree-node .vehicle-area {
        font-size: 14px;
        height 30px;
        /*padding-left: 16px !important;*/
    }

    .fleet-area span {
        float: left;
    }

    .vehicle-area div {
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        height: 30px;
        line-height: 30px;
    }

    .vehicle-area div:nth-child(1) {
        float: left;
    }

    .no-checkbox {
        /*margin-left: -20px;*/
    }

    .number-area {
        float: right !important;
    }

    .custom-tree-node span {
        height: 30px;
        line-height: 30px;
    }

    .custom-tree-node .vehicle-area, .custom-tree-node.fleet-area {
        width: 100%;
    }

    .custom-tree-node .select-hover-area {
        padding-left: 2px;
        width: calc(100% - 32px);
    }

    .custom-tree-node .noFleetIcon {
        width: 100%;
    }

    .custom-tree-node .fleet-area .select-hover-area:hover, .custom-tree-node .vehicle-area:hover {
        background-color: $color_gray_second;
        border-radius: 5px;
    }

    .icon-big {
        padding 0 2px
        font-size: 22px;
        color: $color_black_first;
    }

    .icon-small {
        padding 0 2px
        font-size: 15px;
        color: $color_black_second;
    }

    .filter-tree {
        padding: 15px 10px 15px 5px;
        overflow-y: auto;
        height: calc(100% - 52px);
        -moz-user-select: none; /*火狐*/
        -webkit-user-select: none; /*webkit浏览器*/
        -ms-user-select: none; /*IE10*/
        -khtml-user-select: none; /*早期浏览器*/
        user-select: none;
    }

    .current-node {
        font-weight: bolder !important;
    }

    .current-node-icon {
        font-size: 16px;
        color: $color_blue_first;

    }

    .no-signal-icon {
        font-size: 16px;
        color: $color_black_second;
        float: right;

    }

    .microphone-icon {
        font-size: 18px;
        color: $color_black_second;
        float: right;

    }

    .collision-icon {
        font-size: 14px;
        color: $color_collision;
        float: right;
        margin: 0 5px 0 5px;
    }

    .offroad-node {
        color: $color_black_third
    }

    .no-driver-vehicle {
        color $color_collision
    }

    /* 定义keyframe动画，命名为blink */
    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        50.01% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.5;
        }
    }

    /* 添加兼容性前缀 */
    @-webkit-keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        50.01% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.5;
        }
    }

    @-moz-keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        50.01% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.5;
        }
    }

    @-ms-keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        50.01% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.5;
        }
    }

    @-o-keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        50.01% {
            opacity: 0.5;
        }
        100% {
            opacity: 0.5;
        }
    }

    /* 定义blink类*/
    .blink {
        font-weight: bold;
        color: $color_warning
        animation: blink 1s linear infinite;
        /* 其它浏览器兼容性前缀 */
        -webkit-animation: blink 1s linear infinite;
        -moz-animation: blink 1s linear infinite;
        -ms-animation: blink 1s linear infinite;
        -o-animation: blink 1s linear infinite;
    }

    .data-loading {
        text-align: center;
        height: 50px;
        line-height: 50px;
    }

    >>> .el-input--small .el-input__inner {
        height: 40px;
        line-height: 40px;
    }

    >>> .el-checkbox__inner {
        border-color $color_black_third
    }
</style>


<style lang="stylus">
    @import "~@/assets/stylus/color.styl"

    .filter-tree .el-tree-node__content {
        padding-top: 2px !important;
        padding-left: 5px !important;
    }

    .filter-tree .el-tree-node__expand-icon.is-leaf::before {
        height: 30px;
    }

    .filter-tree .el-tree-node__expand-icon {
        padding: 2px 0;
        margin-left: 10px;
    }

    .filter-tree .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);

    }

    .filter-tree .el-icon-caret-right:before {
        background: url("../../assets/image/tree-fold.svg") 0;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        font-size: 16px;
        background-size: 20px;

    }

    .filter-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        background: url("../../assets/image/tree-unfold.svg") 0;
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        font-size: 16px;
        background-size: 20px;
    }

    .el-tree-node__expand-icon.is-leaf::before {
        display: none;
    }

    .filter-tree .el-tree-node {
        padding: 3px 0;
    }

    .filter-tree .el-tree-node {
        position: relative;
    }

    .filter-tree .el-tree-node__children {
        padding-left: 28px;
        position: relative;
    }

    .filter-tree .el-tree-node:before, .filter-tree .el-tree-node:after {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
    }

    .filter-tree .el-tree-node:before {
        border-left: 1px dashed $color_gray_third;
        bottom: 16px;
        top: -20px;
        width: 1px;
    }

    .filter-tree .el-tree-node:after {
        border-top: 1px dashed $color_gray_third;
        height: 20px;
        top: 17px;
        width: 20px;
    }

    .filter-tree > .el-tree-node:before {
        border-left: none;
    }

    .filter-tree > .el-tree-node:after {
        border-top: none;
    }

    .filter-tree .el-tree-node:last-child:before, .last-visible-fleet:before {
        height: 38px;
    }

    .filter-tree .el-tree-node.is-current > .el-tree-node__content,
    .filter-tree .el-tree-node.is-current > .el-tree-node__content:active,
    .filter-tree .el-tree-node__content:visited,
    .filter-tree .el-tree-node__content:hover,
    .filter-tree .el-tree-node:focus .el-tree-node__content {
        background-color: white !important;
    }

    .filter-tree .el-tree-node.is-current > .el-tree-node__content > .custom-tree-node .fleet-area .select-hover-area,
    .filter-tree .el-tree-node.is-current > .el-tree-node__content > .custom-tree-node .fleet-area .select-hover-area:active {
        background-color: $color_gray_second;
        border-radius: 5px;
    }

    .filter-tree .el-tree-node.is-current > .el-tree-node__content > .custom-tree-node .vehicle-area {
        background-color: $color_gray_first;
    }

    .filter-tree .el-tree-node__content > label.el-checkbox {
        margin-right: 2px;
    }

    .filter-tree .tc-icon
        width 15px
</style>
